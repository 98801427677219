<template>
  <div class="addRole">
    <div class="title">
      <span>系统设置 / </span> <span>角色管理 / </span><span class="black">{{edit?"新增":"编辑"}}</span>
    </div>
    <el-card>
      <el-form :model="form" label-width="93px" ref="form" :rules="rules">
        <el-row :gutter="24">
          <div class="shu">角色名称</div>
          <el-col :xs="24" :md="12">
            <el-form-item label="角色名称：" prop="roleName">
              <el-input v-model="form.roleName" placeholder="请输入角色名称" @blur="getRoleFindByName" maxlength="15">
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="权限字符：" prop="roleCode">
              <el-input v-model="form.roleCode" placeholder="请输入权限字符"></el-input>
            </el-form-item> -->
            <el-form-item label="备注：" prop="roleMemo">
              <el-input v-model="form.roleMemo" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="dataWidth">
          <el-col :xs="24" :md="6">
            <div class="shu">菜单权限</div>
            <div class="all">
              <el-checkbox-group v-model="all" @change="changeAll">
                <el-checkbox label="1" name="type">全选</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="tree_div">
              <el-tree :props="defaultProps" node-key="menuId" @node-click="catalogClick" :data="dataList"
                @check="catalogChecked" ref="tree" :default-checked-keys="catalogCheckedList"
                :default-expand-all="false" :check-strictly="true" show-checkbox>
              </el-tree>
            </div>
          </el-col>
          <el-col :xs="24" :md="6">
            <div class="tree">
              <div class="shu flex" style="width:205px;">数据权限 ( 可查看数据单位 )</div>
              <el-checkbox class="pad_left" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="dataArr" @change="changeCompany" class="pad_left p8">
                <el-checkbox v-for="(item, i) in companyArr" :key="i" :label="item.companyId" name="type">
                  {{ item.companyName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="text_center">
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" style="margin-top:20px;" size="small" @click="addRole(edit?'add':'update')">保存设置
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import {
    roleSave,
    roleUpdate,
    roleInfo,
    newmenuList,
    roleFindByName,
    sysCompany
  } from "../../../api/api";
  export default {
    data() {
      return {
        checkAll: false,
        all: "",
        name: '',
        form: {
          id: '',
          compId: '',
          roleDatetime: "",
          roleFlag: '',
          roleId: '',
          roleMan: 0,
          roleMemo: "",
          roleName: "",
          roleUpdateman: 0,
          roleUpdatetime: "",
          isWarning: "",
          newMenuEntities: []
        },
        powerList: [{
            value: "1",
            label: "数据权限",
          },
          {
            value: "2",
            label: "自定义数据权限",
          },
        ],
        rules: {
          roleName: [{
            required: true,
            message: "请输入角色名称"
          }],
          // roleCode: [{
          //   required: true,
          //   message: "请输入权限字符"
          // }],
        },
        defaultProps: {
          children: "subItem",
          label: "menuName",
        },
        menuIdList: [],
        dataList: [],
        catalogCheckedList: [], //目录被勾选的
        companyArr: [],
        dataArr: [],
      };
    },
    computed: {
      edit() {
        return this.$route.params.id == "true";
      },
      previousForm() {
        return this.$store.state.previousForm;
      },
    },
    created() {
      this.name = ''
      if (!this.edit) {
        this.id = this.$route.params.id
        this.getRoleInfo()
        if (this.$route.params.form) {
          this.$store.commit("setParam", {
            previousForm: this.$route.params.form
          });
        }
      }
      this.getNewmenuList()
      this.getSysCompany()
    },
    mounted() {},
    methods: {
      // 菜单目录
      getNewmenuList() {
        this.$request(newmenuList).then(
          (res) => {
            if (res.code === "200") {
              let data = res.data
              let newData = []
              if (data && data.length) {
                data.find(item => {
                  data.find(val => {
                    if (item.menuId == val.menuParent) {
                      if (item.subItem) {
                        item.subItem.push(val)
                      } else {
                        item.subItem = [val]
                      }
                    }
                  })
                })
                data.find(item => {
                  if (item.menuFuntype == 0) {
                    newData.push(item)
                  }
                })
              }
              this.dataList = newData
            }
          }
        )
      },
      handleCheckAllChange(val) {
        let checkList = []
        let roleCompanyEntities = []
        this.companyArr.map((item) => {
          checkList.push(item.companyId)
          roleCompanyEntities.push(({
            companyId: item.companyId
          }))
        })
        if (this.dataArr = val) {
          this.dataArr = checkList
          this.form.roleCompanyEntities = roleCompanyEntities
        } else {
          this.dataArr = []
          this.form.roleCompanyEntities = []

        }

      },
      // 新增
      addRole(val) {
        let list = this.$refs.tree.getCheckedKeys()
        this.form.newMenuEntities = list.map(item => {
          return {
            menuId: item
          }
        })
        this.$refs.form.validate((result) => {
          if (result) {
            this.$request(val == "update" ? roleUpdate : roleSave, this.form).then(
              (res) => {
                if (res.code === "200") {
                  this.$util.message("suc", "操作成功");
                  this.$router.push({
                    name: "role",
                    params: {
                      form: this.previousForm
                    }
                  });
                  setTimeout(() => {
                    this.$common.getUserMenuList()
                  }, 100)

                }
              }
            )
          }

        })
      },
      //获取数据权限单位列表
      getSysCompany() {
        this.$request(sysCompany).then(
          (res) => {
            if (res.code === "200") {
              this.companyArr = res.data

            }
          }
        )
      },
      changeCompany(e) {
        if (e) {
          let checkedCount = e.length;
          this.checkAll = checkedCount === this.companyArr.length;
          this.form.roleCompanyEntities = []
          e.forEach((item) => {
            this.form.roleCompanyEntities.push({
              companyId: item
            })
          })
        }

      },
      getRoleInfo() {
        this.$request(roleInfo(this.id)).then(
          (res) => {
            if (res.code === "200") {
              this.form = res.data
              this.name = this.form.roleName
              this.form.roleCompanyEntities.find((i) => {
                this.dataArr.push(i.companyId)
              })
              res.data.newMenuEntities.map((item) => {
                this.catalogCheckedList.push(item.menuId)
              })
              this.allTree(this.catalogCheckedList, true)
            }
          }
        )
      },
      // 验证角色名称是否存在
      getRoleFindByName() {
        if ((this.name == this.form.roleName) || !this.form.roleName) return
        this.$request(roleFindByName(this.form.roleName)).then((res) => {
          if (res.code === "200") {
            if (res.data == "false") {
              this.form.roleName = this.name || "";
              this.$util.message("err", "该角色名称已存在");
            }
          }
        });
      },

      //   全选
      changeAll() {
        this.$forceUpdate()
        if (this.dataList && this.dataList.length) {
          this.allTree(this.dataList, this.all);
        }
      },
      // 改变全选的递归
      allTree(list, bool) {
        list.find((i) => {
          if (i.subItem && i.subItem.length) {
            this.allTree(i.subItem, bool);
          }
          this.$nextTick((_) => {
            this.$refs.tree.setChecked(i, bool);
          });
        });
      },
      //   点击事件
      catalogClick(data, node) {
        console.log("我是点击", data, node);
      },
      //   勾选事件
      catalogChecked(data, node) {
        let thisId = data.menuId //当前点击的id
        let list = node.checkedKeys //选中的id组成数组
        let isTrue = list.indexOf(thisId) > -1 //当前点击为选中时为true
        this.clickBtn(this.dataList, data, isTrue)
        console.log("我是勾选事件", data, node);
      },
      // 自定义勾选联动方法
      clickBtn(list, data, bool) {
        list.find((i) => {
          if (i.menuId == data.menuId) {
            this.$nextTick((_) => {
              this.$refs.tree.setChecked(i, bool);
            });
            if (!bool) {
              this.cancelChecked(this.dataList, data, bool)
            }
          }
          if (i.subItem && i.subItem.length) {
            this.clickBtn(i.subItem, data, bool);
          }
          if (bool && i.menuId == data.menuParent) {
            this.clickBtn(this.dataList, i, bool)
          }
        });
      },
      // 取消  
      cancelChecked(list, data, bool) {
        list.find(item => {
          if (item.menuId == data.menuId) {
            this.$nextTick((_) => {
              this.$refs.tree.setChecked(item, bool);
            });
            if (item.subItem && item.subItem.length) {
              this.cancelTree(item.subItem, bool)
            }
          }
          if (item.subItem && item.subItem.length) {
            this.cancelChecked(item.subItem, data, bool)
          }
        })
      },
      // 取消的递归
      cancelTree(list, bool) {
        list.find(item => {
          this.$nextTick((_) => {
            this.$refs.tree.setChecked(item, bool);
          });
          if (item.subItem && item.subItem.length) {
            this.cancelTree(item.subItem, bool)
          }
        })
      }
    },
  };
</script>

<style lang="less" scoped>
  .addRole {
    .all {
      padding: 8px 0px 17px 10px;
      font-size: 16px;
      font-weight: 500;
    }

    .dataWidth {
      // width: 1000px;
      min-width: 800px;
    }

    .tree_div {
      padding: 0px 0px 5px 6px;
    }
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color:  #606266;
}
    /deep/.el-tree-node__content {
      height: 30px;
    }

    /deep/.el-checkbox__inner {
      height: 20px;
      width: 20px;
    }

    /deep/.el-checkbox__inner::after {
      left: 7px;
      top: 3px;
    }

    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 7px;
    }

    /deep/.tree {
      .el-checkbox {
        display: block;
        margin-top: 10px;
      }
    }

    .pad_left {
      padding-left: 10px;
    }

    .p8 {
      padding-left: 28px;
    }
  }
</style>